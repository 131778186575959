<template>
  <section class="recommended-sellers-block border-0 pt-0">
    <section class="container">
      <section class="text-center">
        <h2 class="recommended-sellers-block__title">
          {{ $t('main.why_goldcenter.title') }}
        </h2>
        <p class="recommended-sellers-block__description">
          {{ $t('main.why_goldcenter.descriptions') }}
        </p>
      </section>
      <section class="d-grid rison-items-container" v-if="!switchRoute">
        <section class="rison-item">
          <section class="rison-item__figure">
            <figure
              class="rison-item__figure__icon d-flex justify-content-center align-items-center w-100 h-100 element-background"
            >
              <img src="~/assets/images/gold/coohse-security.svg" :alt="`first_reason_title icon`" width="61" height="60" data-not-lazy />
            </figure>
          </section>
          <article>
            <h3 class="text-center tooltip-title">
              {{ $t('main.why_goldcenter.first_reason_title') }}
            </h3>
            <p class="rison-item__name text-center">
              {{ $t('main.why_goldcenter.first_reason') }}
            </p>
          </article>
        </section>
        <section class="rison-item">
          <section class="rison-item__figure">
            <figure
              class="rison-item__figure__icon d-flex justify-content-center align-items-center w-100 h-100 element-background"
            >
              <img src="~/assets/images/gold/coohse-quality.svg" :alt="`second_reason_title icon`" width="61" height="60" data-not-lazy />
            </figure>
          </section>
          <article>
            <h3 class="text-center tooltip-title">
              {{ $t('main.why_goldcenter.second_reason_title') }}
            </h3>
            <p class="rison-item__name text-center">
              {{ $t('main.why_goldcenter.second_reason') }}
            </p>
          </article>
        </section>
        <section class="rison-item">
          <section class="rison-item__figure">
            <figure
              class="rison-item__figure__icon d-flex justify-content-center align-items-center w-100 h-100 element-background"
            >
              <img src="~/assets/images/gold/coohse-rich.svg" :alt="`third_reason_title icon`" width="61" height="60" data-not-lazy />
            </figure>
          </section>
          <article>
            <h3 class="text-center tooltip-title">
              {{ $t('main.why_goldcenter.third_reason_title') }}
            </h3>
            <p class="rison-item__name text-center">
              {{ $t('main.why_goldcenter.third_reason') }}
            </p>
          </article>
        </section>
        <section class="rison-item">
          <section class="rison-item__figure">
            <figure
              class="rison-item__figure__icon d-flex justify-content-center align-items-center w-100 h-100 element-background"
            >
              <img src="~/assets/images/gold/coohse-exp.svg" :alt="`fourth_reason_title icon`" width="61" height="60" data-not-lazy />
            </figure>
          </section>
          <article>
            <h3 class="text-center tooltip-title">
              {{ $t('main.why_goldcenter.fourth_reason_title') }}
            </h3>
            <p class="rison-item__name text-center">
              {{ $t('main.why_goldcenter.fourth_reason') }}
            </p>
          </article>
        </section>
      </section>
      <section class="d-grid rison-items-container" v-else>
        <section class="rison-item">
          <section class="rison-item__figure">
            <figure
              class="rison-item__figure__icon d-flex justify-content-center align-items-center w-100 h-100 element-background"
            >
              <img src="~/assets/images/silver/coohse-security.svg" :alt="`first_reason_title icon`" width="61" height="60" data-not-lazy />
            </figure>
          </section>
          <article>
            <h3 class="text-center tooltip-title">
              {{ $t('main.why_goldcenter.first_reason_title') }}
            </h3>
            <p class="rison-item__name text-center">
              {{ $t('main.why_goldcenter.first_reason') }}
            </p>
          </article>
        </section>
        <section class="rison-item">
          <section class="rison-item__figure">
            <figure
              class="rison-item__figure__icon d-flex justify-content-center align-items-center w-100 h-100 element-background"
            >
              <img src="~/assets/images/silver/coohse-quality.svg" :alt="`second_reason_title icon`" width="61" height="60" data-not-lazy />
            </figure>
          </section>
          <article>
            <h3 class="text-center tooltip-title">
              {{ $t('main.why_goldcenter.second_reason_title') }}
            </h3>
            <p class="rison-item__name text-center">
              {{ $t('main.why_goldcenter.second_reason') }}
            </p>
          </article>
        </section>
        <section class="rison-item">
          <section class="rison-item__figure">
            <figure
              class="rison-item__figure__icon d-flex justify-content-center align-items-center w-100 h-100 element-background"
            >
              <img src="~/assets/images/silver/coohse-rich.svg" :alt="`third_reason_title icon`" width="61" height="60" data-not-lazy />
            </figure>
          </section>
          <article>
            <h3 class="text-center tooltip-title">
              {{ $t('main.why_goldcenter.third_reason_title') }}
            </h3>
            <p class="rison-item__name text-center">
              {{ $t('main.why_goldcenter.third_reason') }}
            </p>
          </article>
        </section>
        <section class="rison-item">
          <section class="rison-item__figure">
            <figure
              class="rison-item__figure__icon d-flex justify-content-center align-items-center w-100 h-100 element-background"
            >
              <img src="~/assets/images/silver/coohse-exp.svg" :alt="`fourth_reason_title icon`" width="61" height="60" data-not-lazy />
            </figure>
          </section>
          <article>
            <h3 class="text-center tooltip-title">
              {{ $t('main.why_goldcenter.fourth_reason_title') }}
            </h3>
            <p class="rison-item__name text-center">
              {{ $t('main.why_goldcenter.fourth_reason') }}
            </p>
          </article>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  name: 'WhyGoldencenter',
  computed: {
    switchRoute () {
      return this.$store.state.siteMode.sitemode === 'silver'
    }
  }
}
</script>

<style scoped>
.recommended-sellers-block{
  margin-top: 50px;
}
.tooltip-title {
  color: var(--biscay, #000);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
</style>
